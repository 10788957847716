<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="320" persistent>
        <v-card>
          <v-card-title>{{ $vuetify.lang.t("$vuetify.key.K_%E5%AE%9E%E6%97%B6%E8%A7%86%E9%A2%91%E5%9C%B0%E5%9D%80%E9%85%8D%E7%BD%AE") }}</v-card-title>
          <v-card-text>
            <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E8%AE%BE%E5%A4%87%E5%9C%B0%E5%9D%80')" v-model="host"></v-text-field>
            <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E8%AE%BE%E5%A4%87%E7%AB%AF%E5%8F%A3')" v-model="port"></v-text-field>
            <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D')" v-model="account"></v-text-field>
            <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E5%AF%86%E7%A0%81')" v-model="password"></v-text-field>
            <v-select
              :label="$vuetify.lang.t('$vuetify.key.K_%E8%A7%86%E9%A2%91%E5%8E%82%E5%95%86')"
              v-model="manufacturer"
              :items="manufacturers"
            ></v-select>
            <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E9%80%9A%E9%81%93')" v-model="channel"></v-text-field>
            <v-select
              :label="$vuetify.lang.t('$vuetify.key.K_%E7%A0%81%E6%B5%81')"
              v-model="streamType"
              :items="streamTypes"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
            <v-btn text @click="show = false">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import client from "../../utils/client";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
      host: "",
      port: 554,
      account: "",
      password: "",
      manufacturer: 1,
      manufacturers: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%B7%E5%BA%B7"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A4%A7%E5%8D%8E"), value: 2 },
      ],
      channel: "1",
      streamType: "2",
      streamTypes: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%B8%BB%E7%A0%81%E6%B5%81"), value: "1" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%AD%90%E7%A0%81%E6%B5%81"), value: "2" },
      ],
    };
  },
  methods: {
    submit() {
      if (
        !this.host ||
        !this.port ||
        !this.account ||
        !this.password ||
        this.channel == ""
      ) {
        client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E8%AF%B7%E8%BE%93%E5%85%A5%E5%AE%8C%E6%95%B4%E4%BF%A1%E6%81%AF"));
        return;
      }
      this.show = false;
      let url = "";
      if (this.manufacturer == 1) {
        url = `rtsp://${this.account}:${this.password}@${this.host}:${this.port}/Streaming/Channels/${this.channel}0${this.streamType}`;
      } else if (this.manufacturer == 2) {
        url = `rtsp://${this.account}:${this.password}@${this.host}:${
          this.port
        }/cam/realmonitor?channel=${this.channel}&subtype=${
          this.streamType - 1
        }`;
      }
      this.change(url);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
